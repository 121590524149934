<template>
  <div class="videoRoom">
    <div class="videoRoom_header">
      <div class="videoRoom_header_left">
        <img src="@/assets/record-logo.png" alt style="margin-right:10px;" @click="topage('/')" />
        <!-- <span>{{classDetail.courseName}} ></span> -->
        <span>{{ videoObj.classHourName }}</span>
        <!-- <img src="" alt=""> -->
      </div>
      <div class="videoRoom_header_right" style="min-width:32vw;display:flex;justify-content: space-between;">
        <div style="display:flex;">
          <!-- <p>
            <span >关注微信公众号</span>
          </p>-->
          <div class="account">
            <span>关注微信公众号</span>
            <div class="account-box">
              <img :src="website.qrCodeSidebar" alt />
            </div>
          </div>

          <!-- <p>
            <span>下载APP</span>
            <img src="@/assets/home/fire.png" alt="">
          </p>-->
          <div class="dow-app">
            <span>下载APP</span>
            <img src="@/assets/home/fire.png" alt />
            <div class="dow-box">
              <img :src="website.qrCodeApp" alt />
            </div>
          </div>
        </div>

        <img :src="userInfo.headPic" alt class="videoRoom_header_right_headerImg" style="border-radius:50%;cursor:pointer;" @click="toUser()" />
      </div>
    </div>
    <div class="videoRoom_main">
      <div :class="drawer ? 'videoRoom_main_left' : 'videoRoom_main_leftstretch'">
        <div id="player" style="margin-left:20px;margin-bottom:30px;"></div>
        <!-- <video src="http://39.96.9.53:5004/videos/5f0a0e07-aa1f-4239-a7fa-05d7de7804fe.mp4" autoplay="true" controls="true" style="width:100%;height:100%;"></video> -->
      </div>
      <div :class="drawer ? 'videoRoom_main_right' : 'videoRoom_main_rightstretch'">
        <div class="videoRoom_main_right_content" style="position:relative">
          <el-drawer :title="title" :visible.sync="drawer" :before-close="handleClose" :modal="false" :show-close="false">
            <el-menu
              :default-openeds="openeds"
              class="el-menu-vertical-demo"
              @open="handleOpen"
              @close="handleClose"
              text-color="#333"
              active-text-color="#ffd04b"
              @select="select"
              v-if="sideSwitchIndex == '0'"
            >
              <el-submenu :index="JSON.stringify(key + 1)" v-for="(listItem,key) in chapterList" :key="key">
                <template slot="title">
                  <el-tooltip class="item" effect="light" :content="listItem.name" placement="bottom-start">
                    <span class="muTitleBox">{{ listItem.name }}</span>
                  </el-tooltip>
                  
                </template>
                <el-menu-item v-for="(childer,childerIndex) in listItem.hours" :key="childerIndex" :index="JSON.stringify(childer.id)" :class="{ on: videoObj.classHourId == childer.id }">
                  <div
                    class
                    @click="chooseVideo(childer)"
                    style="cursor:pointer;width:100%;height:74px;display:flex;align-items: flex-start;justify-content: space-between;padding:10px 5%;box-sizing:border-box;flex-flow: column;line-height: initial;"
                  >
                    <el-tooltip class="item" effect="light" :content="childer.classHourName" placement="bottom-start">
                      <span class="muTitleBox" style="color:#D3D3D3;font-size:12px;">{{ childer.classHourName }}</span>
                    </el-tooltip>
                    <!-- <p
                      style="color:#D3D3D3;font-size:12px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;"
                    >{{ childer.classHourName }}</p> -->
                    <p style="width:100%;display: flex;justify-content: space-between;align-items: center;">
                      <span style="font-size:10px !important;color:#D3D3D3;">{{ getHMS(childer.videoTimeLength) }}</span>
                      <span v-if="videoObj.classHourId == childer.id" style="color:#5F7CFD;font-size:11px;">
                        <img src="@/assets/bofang.png" style="width:15px;height:10px;margin-right:10px;" alt />播放中
                      </span>
                      <span v-if="videoObj.classHourId != childer.id" style="color:#D3D3D3;font-size:11px;">立即学习</span>
                    </p>
                  </div>
                </el-menu-item>
              </el-submenu>
            </el-menu>
            <div class="videoRoom_main_right_content_handouts" v-if="sideSwitchIndex == '1'">
              <div class="videoRoom_main_right_content_handouts_item" v-for="(item,index) in handoutsList" :key="index">
                <p class="videoRoom_main_right_content_handouts_item_left">
                  <img src="@/assets/course/handouts_icon.png" alt />
                  <el-tooltip class="item" effect="light" :content="item.coursewareName" placement="bottom-start">
                    <span>{{ item.coursewareName }}</span>
                  </el-tooltip>
                  
                </p>
                <p class="videoRoom_main_right_content_handouts_item_right">
                  <img src="@/assets/course/download_icon.png" alt @click="goHandouts(item)" />
                </p>
              </div>
            </div>
            <div class="videoRoom_main_right_content_ask" v-if="sideSwitchIndex == '2' && isShowAsk == false">
              <div class="videoRoom_main_right_content_ask_list">
                <div class="videoRoom_main_right_content_ask_list_item" v-for="(item,index) in askList" :key="index">
                  <div class="videoRoom_main_right_content_ask_list_item_top">
                    <p>问</p>
                    <p class="questions">{{ item.questionContent }}</p>
                  </div>
                  <div class="videoRoom_main_right_content_ask_list_item_bottom" v-if="item.answerContent != '' && item.answerContent != null && item.answerContent != undefined">
                    <p class="answer">答</p>
                    <span class="huida_content" style="width:100%;" v-html="item.answerContent"></span>
                  </div>
                </div>
              </div>
              <div class="videoRoom_main_right_content_ask_btn">
                <el-button type="primary" @click="tiwen()">提问</el-button>
              </div>
            </div>
            <div class="videoRoom_main_right_content_askQuiz" v-if="sideSwitchIndex == '2' && isShowAsk == true">
              <!-- <div class="videoRoom_main_right_content_askQuiz_explain">针对题库的提问</div> -->
              <div class="videoRoom_main_right_content_askQuiz_box">
                <!-- <div class="videoRoom_main_right_content_askQuiz_box_header">问题*：</div> -->
                <el-input type="textarea" :rows="5" placeholder="请输入您要提问的内容..." v-model="askTextarea" maxlength="300" resize="none" show-word-limit="true"></el-input>
                <fileUpload isList :options="{}" @fileCallback="fileBack"></fileUpload>
                <!-- <div class="videoRoom_main_right_content_askQuiz_box_upLoad">
                                    <span class="videoRoom_main_right_content_askQuiz_box_upLoad_icon">
                                        <img src="@/assets/ask/pic_icon.png" alt="">
                                    </span>
                </div>-->
                <div class="videoRoom_main_right_content_noteView_btn">
                  <!-- <el-button type="info" @click="dayi_cancel()">取消</el-button> -->
                  <el-button type="primary" @click="dayi_add()">提交</el-button>
                </div>
              </div>
            </div>
            <div class="videoRoom_main_right_content_note" v-if="sideSwitchIndex == '3' && isShowNote == false">
              <div class="videoRoom_main_right_content_note_header" @click="noteAdd()" style="cursor:pointer;">
                <!-- <img src="@/assets/course/" alt=""> -->
                <!-- <span style="cursor:pointer;color:#007aff">新增笔记</span> -->
                <el-button type="primary" style="cursor:pointer;">新增笔记</el-button>
              </div>
              <div class="videoRoom_main_right_content_note_list">
                <div class="videoRoom_main_right_content_note_list_item" v-for="(item,index) in noteList" :key="index" @click="note_amend(item)">
                  <p class="videoRoom_main_right_content_note_list_item_text">{{ item.content }}</p>
                  <p class="videoRoom_main_right_content_note_list_item_time">{{ item.intime }}</p>
                </div>
              </div>
            </div>
            <div class="videoRoom_main_right_content_noteView" v-if="sideSwitchIndex == '3' && isShowNote == true">
              <div class="videoRoom_main_right_content_noteView_text">
                <el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="noteTextarea" resize="none"></el-input>
              </div>
              <div class="videoRoom_main_right_content_noteView_btn">
                <el-button type="info" @click="cancelNote()">取消</el-button>
                <el-button type="primary" @click="saveNote()" v-if="isSaveOrAmend == 1">保存</el-button>
                <el-button type="primary" @click="amendNote()" v-if="isSaveOrAmend == 2">修改</el-button>
              </div>
              <div class="videoRoom_main_right_content_noteView_list">
                <div class="videoRoom_main_right_content_noteView_list_item" v-for="(item,index) in noteList" :key="index" @click="clickNote(item)">
                  <p class="videoRoom_main_right_content_noteView_list_item_text">{{ item.content }}</p>
                  <!-- <p class="videoRoom_main_right_content_noteView_list_item_time">{{item.intime}}</p> -->
                </div>
              </div>
            </div>
          </el-drawer>
        </div>
        <div class="videoRoom_main_right_sideSwitch" style="min-width:3.3vw;">
          <p>
            <img src="@/assets/course/fold_icon.png" alt style="width:27px;height:19px;" @click="popup" />
          </p>
          <p v-for="(item,index) in sideSwitchList" :key="index" :class="sideSwitchIndex == item.id ? 'sideSwitch_active' : ''" @click="tabView(item)">
            <img src="@/assets/course/catalogue.png" alt v-if="item.id == '0'" style="width:23px;height:17px;" />

            <img src="@/assets/course/handouts.png" alt v-if="item.id == '1' && sideSwitchIndex != '1'" style="width:23px;height:20px;" />
            <img src="@/assets/course/handouts_block.png" alt v-if="item.id == '1' && sideSwitchIndex == '1'" style="width:23px;height:20px;" />

            <img src="@/assets/course/quiz.png" alt v-if="item.id == '2'" style="width:23px;height:20px;" />

            <img src="@/assets/course/download_icon.png" alt v-if="item.id == '3'" style="width:23px;height:23px;" />

            <span>{{ item.name }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="warn_messageBox" v-if="isDelShow">未找到课时,请重新选择课时观看</div>
  </div>
</template>
<script>
import fileUpload from "../../components/FileUpload.vue";
import { wangzhanpeizhi } from "@/api/login";
import { package_isBuy } from '@/api/livebroadcast';
import {
  course_chapters,
  coursepackage_courseware,
  coursepackage_dayiList,
  classhour_noteList,
  dayi_add,
  note_add,
  note_edit,
  common_playerSafe,
  planReportedData,
  panduanjindu
} from "@/api/other";
export default {
  name: "videoRoom",
  components: {
    fileUpload,
  },
  data() {
    return {
      isDelShow:false,
      drawer: true,
      sideSwitchList: [
        {
          id: "0",
          name: "目录",
        },
        {
          id: "1",
          name: "讲义",
        },
        {
          id: "2",
          name: "提问",
        },
        {
          id: "3",
          name: "笔记",
        },
      ],
      sideSwitchIndex: "0",
      chapterList: [],
      vodPlayerJs: "https://player.polyv.net/script/player.js",
      vid: "",
      videoObj: {},
      coursePackageId: "",
      courseId: "",
      classDetail: {},
      clickItem: {},
      handoutsList: [],
      title: "目录列表",
      askList: [],
      noteList: [],
      isShowNote: false,
      isShowAsk: true,
      askTextarea: "",
      noteTextarea: "",
      isSaveOrAmend: 1,
      noteObj: {},
      videoToken: {},
      player: {},
      currentTime: 0,
      Token: "",
      isUserInfo: false,
      userInfo: {},
      isFullFill: 0,
      interval: null,
      openeds: [],
      website: {},
      videoRoomType: 0,
      timers:null,
    };
  },
  mounted() {
    var that = this
    this.timers = null;
    // this.liveSdk()
    var pageQuery = that.$route.query;
    var videoRoomInfo = localStorage.getItem("videoRoom")
    if (videoRoomInfo) {
      videoRoomInfo = JSON.parse(videoRoomInfo)

      that.videoRoomType = videoRoomInfo.type;
      that.videoObj = videoRoomInfo.videoObj;
      that.vid = that.videoObj.videoId;
      that.classDetail = videoRoomInfo.classDetail;
      that.clickItem = videoRoomInfo.clickItem;
    }
    that.currentTime = pageQuery.studyTimeLength;
    that.coursePackageId = pageQuery.coursePackageId;
    that.videoObj.classHourId = pageQuery.classHourId
    that.courseId = pageQuery.courseId

    if(this.videoObj?.classHourName){
      document.title = this.videoObj.classHourName+'-萨恩课堂';
    }
    // that.loadPlayerScript(that.loadPlayer);
    // that.coursePackageId=that.$route.query.coursePackageId
    that.course_chapters();
    // window.s2j_onPlayOver = function () {
    //   console.log("over");
    // };
    that.userInfo = JSON.parse(localStorage.getItem("setuserInfo"));

    
    // if (that.classDetail.isBuy == 0) {
    //   console.log("我没有购买此课程");
    //   var arr = [{ id: "0", name: "目录" }];
    //   that.sideSwitchList = arr;
    // }
    this.vid && this.pageLoad();
  },
  destroyed(){
    clearTimeout(this.timers);
  },
  methods: {
    pageLoad(){
      if (this.classDetail.isFree == 0) {
        var arr = [{ id: "0", name: "目录" }, { id: "1", name: "讲义" }];
        this.sideSwitchList = arr;
      }
      this.panduanjindu();
      this.wangzhanpeizhi();
      setTimeout(() => {
        this.getcommon_playerSafe();
      }, 1000)
      this.package_isBuy();
    },
    package_isBuy() {
      package_isBuy({ videoId: this.vid }).then(res => {
        if (res.data.code == 0) {
          let isBuy = res.data.data.isBuy;
          if (isBuy == 0) {
            this.$router.replace('/');
          }else{
            if(res.data.data.isDel === 1){
              this.isDelShow = true;
              this.timers = setTimeout(()=>{
                this.isDelShow = false;
                clearTimeout(this.timers);
              },2000)

            }
          }
        } else {
          this.$message.error(res.data.msg);
        }
      })
    },
    toUser() {
      let routeUrl = this.$router.resolve({
        path: '/myself/myCenter',
        query: {}
      });
      window.open(routeUrl.href, '_blank');
    },
    wangzhanpeizhi() {
      wangzhanpeizhi().then(res => {
        if (res.data.code == 0) {
          this.website = res.data.data;
        }
      }).catch(error => {
        console.log(error);
      })
    },
    // 跳转页面
    topage(page) {
      // this.$router.push({
      //   path: page,
      //   query: {}
      // });
      let routeUrl = this.$router.resolve({
        path: '/',
        query: {}
      });
      window.open(routeUrl.href, '_blank');
    },
    // 判断是否可以操作进度
    panduanjindu() {
      var obj = {
        videoId: this.vid
      };
      panduanjindu(obj)
        .then((res) => {
          // console.log("判断是否可拖动",res);
          if (res.data.code == 0) {
            if (res.data.data.isFullFill == 1) {
              this.isFullFill = 1;
            }
            this.isFullFill = 1;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getHMS(time) {
      const hour =
        parseInt(time / 3600) < 10
          ? "0" + parseInt(time / 3600)
          : parseInt(time / 3600);
      const min =
        parseInt((time % 3600) / 60) < 10
          ? "0" + parseInt((time % 3600) / 60)
          : parseInt((time % 3600) / 60);
      const sec =
        parseInt((time % 3600) % 60) < 10
          ? "0" + parseInt((time % 3600) % 60)
          : parseInt((time % 3600) % 60);
      if (hour == "00") {
        return min + ":" + sec;
      } else {
        return hour + ":" + min + ":" + sec;
      }
    },
    // 父传子
    getTodos(e) {
      this.isUserInfo = e;
    },
    fileBack(fileList) {
      this.fileList = fileList;
    },
    chooseVideo(item) {
      // return false;
      if (item.isAudition == 0 && this.classDetail.isBuy == 0 && this.classDetail.isFree == 1) {
        this.$message.error("你无权限看此视频");
        return false;
      }
      var that = this;
      that.vid = item.videoId;
      that.token = item.videoToken;
      that.videoObj.classHourId = item.id;
      this.currentTime = item.studyTimeLength;
      var obj = {
        videoId: item.videoId,
      };
      common_playerSafe(obj)
        .then((res) => {
          if (res.data.code == 200) {
            that.videoToken = res.data.data;
            that.vid = res.data.data.videoId;
            that.Token = res.data.data.token;
            var setting = {
              vid: res.data.data.videoId,
              autoplay: true,
              playsafe: res.data.data.token,
              watchStartTime: item.studyTimeLength
            };
            that.player.changeVid(setting); // 切换下一个视频
            // that.loadPlayerScript(that.loadPlayer);
            // that.player.on('s2j_onPlayOver', () => {
            // console.info('视频播放完毕');

            // });
            this.loadPlayerInterval();
          } else {
            that.$message.error(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    noteAdd() {
      this.isShowNote = true;
      this.isSaveOrAmend = 1;
      this.noteTextarea = "";
    },

    note_amend(item) {
      this.isShowNote = true;
      this.isSaveOrAmend = 2;
      this.noteObj = item;
      this.noteTextarea = item.content;
    },
    clickNote(item) {
      this.isSaveOrAmend = 2;
      this.noteObj = item;
      this.noteTextarea = item.content;
    },
    tiwen() {
      this.isShowAsk = true;
      this.askTextarea = "";
    },
    dayi_cancel() {
      this.isShowAsk = false;
    },
    // 答疑添加
    dayi_add() {
      let fileList = this.fileList;
      console.log('fileList',fileList);
      let str = "";
      if (fileList != undefined) {
        fileList.forEach((item, index) => {
          str += item.url + ",";
        });
      }
      if (str != "") {
        str = str.substring(0, str.lastIndexOf(","));
      }
      var obj = {
        classHourId: this.videoObj.classHourId,
        courseId: this.clickItem.courseId,
        questionContent: this.askTextarea,
        questionPics: str,
        coursePackageId: this.coursePackageId,
      };
      dayi_add(obj)
        .then((res) => {
          if (res.data.code == 0) {
            this.askTextarea = "";
            this.fileList = [];
            this.coursepackage_dayiList();
            this.sideSwitchIndex = '0';
            // this.isShowAsk = false;
            this.$message.success('提交成功！');
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 笔记添加
    saveNote() {
      var obj = {
        classHourId: this.videoObj.classHourId,
        content: this.noteTextarea,
        courseId: this.clickItem.courseId,
        coursePackageId: this.coursePackageId,
      };
      note_add(obj)
        .then((res) => {
          if (res.data.code == 0) {
            this.isShowNote = false;
            this.classhour_noteList();
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //取消
    cancelNote() {
      this.isShowNote = false;
    },
    // 笔记修改
    amendNote() {
      var obj = {
        id: this.noteObj.id,
        content: this.noteTextarea,
      };
      note_edit(obj)
        .then((res) => {
          if (res.data.code == 0) {
            this.classhour_noteList();
            this.isShowNote = false;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 获取视频加密凭证
    getcommon_playerSafe() {
      var that = this;
      var vid = ''
      // console.log(this.videoRoomType);
      if (that.videoRoomType == 0) {
        vid = that.vid
      } else {
        vid = that.chapterList[0].hours[0].videoId
      }
      var obj = {
        };
      common_playerSafe({
        videoId: vid,
      })
        .then((res) => {
          if (res.data.code == 200) {
            that.videoToken = res.data.data;
            that.vid = res.data.data.videoId;
            that.Token = res.data.data.token;
            // that.vid=res.data.data.videoId
            // if(JSON.stringify(this.videoObj)!='{}' && this.videoObj!=null && this.videoObj!=undefined && this.videoObj.videoPath!='' && this.videoObj.videoPath!=null && this.videoObj.videoPath!=undefined){
            //     var arr=this.videoObj.videoPath.split('?')
            //     console.log(arr)
            //     var arrTh=arr[1].split('=')
            //     console.log('vid',arrTh[1])
            //     this.vid=arrTh[1]
            // }
            that.loadPlayerScript(that.loadPlayer);
          } else {
            that.$message.error(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 下载
    goHandouts(item) {
      console.log("item",item)
      // let routeData = this.$router.resolve({ path: item.filePath, query: {} });
      window.open(item.filePath, "_blank");
    },
    // 右侧切换
    tabView(item) {
      this.sideSwitchIndex = item.id;
      if (item.id == "0") {
        this.title = "目录列表";
        this.course_chapters();
      } else if (item.id == "1") {
        this.coursepackage_courseware();
        this.title = "讲义下载列表";
      } else if (item.id == "2") {
        this.title = "我的提问";
        this.coursepackage_dayiList();
      } else if (item.id == "3") {
        this.title = "课程笔记";
        this.classhour_noteList();
      }
    },
    popup() {
      this.drawer = !this.drawer;
      if (this.drawer == true) {
      }
    },
    handleOpen() { },
    handleClose() { },
    // 课程安排（章节）
    course_chapters() {
      var obj = {
        coursePackageId: this.coursePackageId,
        courseId: this.clickItem.courseId,
      };
      course_chapters(obj)
        .then((res) => {
          if (res.data.code == 0) {
            this.chapterList = res.data.data;
            var arr = [];
            for (var i = 0; i < this.chapterList.length; i++) {
              arr.push((i + 1) + "")
            }
            this.openeds = arr;

            if(!this.vid){
              const classHourId = this.videoObj.classHourId
              let checkItem = ''
              let pageTit = ''
              this.chapterList.forEach(item=>{
                item.hours.forEach(item2=>{
                  if(classHourId == item2.id){
                    checkItem = item2;
                    pageTit = item.name;
                  }
                })
              })
              if(checkItem){
                document.title = pageTit+'-萨恩课堂'
                this.vid = checkItem.videoId
                this.videoObj = {
                  classHourId: checkItem.id,
                  videoId: checkItem.videoId,
                  classHourName: pageTit,
                }
                this.classDetail = {
                  isBuy:1,
                  courseName:'获取值'
                };
                this.clickItem = {
                  courseId:this.courseId
                };
                this.pageLoad()
              }
            }

            // if (res.data.data.length > 0 && res.data.data[0].hours.length > 0) {
              // this.currentTime = res.data.data[0].hours[0].studyTimeLength;
              // console.log(res.data.data[0].hours[0].studyTimeLength);
            // }
            this.vid && this.package_isBuy(this.vid);

          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 讲义列表
    coursepackage_courseware() {
      var obj = {
        courseId: this.clickItem.courseId,
      };
      coursepackage_courseware(obj)
        .then((res) => {
          if (res.data.code == 0) {
            this.handoutsList = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 课程课时答疑列表
    coursepackage_dayiList() {
      var obj = {
        courseId: this.clickItem.courseId,
        classHourId: this.videoObj.classHourId,
        coursePackageId: this.coursePackageId,
      };
      coursepackage_dayiList(obj)
        .then((res) => {
          if (res.data.code == 0) {
            this.askList = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 课程课时笔记列表
    classhour_noteList() {
      var obj = {
        courseId: this.clickItem.courseId,
        classHourId: this.videoObj.classHourId,
        coursePackageId: this.coursePackageId,
      };
      classhour_noteList(obj)
        .then((res) => {
          if (res.data.code == 0) {
            this.noteList = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 下拉的选择
    select(key, keyPath) {
      console.log(key,keyPath);
    },
    // liveSdk(){
    //     liveSdk.on(PolyvLiveSdk.EVENTS.CHANNEL_DATA_INIT, (event, data) => {
    //         liveSdk.setupPlayer({
    //             pptEl: '#ppt',
    //             el: '#player',
    //             type: 'auto'
    //         });
    //     });
    // }
    // 视频创建
    loadPlayerScript(callback) {
      if (!window.polyvPlayer) {
        const myScript = document.createElement("script");
        myScript.setAttribute("src", this.vodPlayerJs);
        myScript.onload = callback;
        document.body.appendChild(myScript);
      } else {
        callback();
      }
    },
    // 视频播放
    loadPlayer() {
      // console.log('视频初始化');
      // console.log(this.currentTime);
      var v = "on";
      var isFullFill = this.isFullFill;
      if (isFullFill == 1) {
        v = "off";
      }
      const polyvPlayer = window.polyvPlayer;

      this.player = polyvPlayer({
        wrap: "#player",
        width: 800,
        height: 533,
        vid: this.vid,
        // vid:'a7a317c4dcae8e13076bb0d7fd555026_a',
        playsafe: this.Token,
        autoplay: true,
        watchStartTime: this.currentTime,
        showHd: true, // 播放器控制栏是否显示清晰度切换的按钮，默认为 true
        show_rate: 2, // 允许选择的最高清晰度，取值：{1,2}。值为1时，只显示流畅，值为2时，可选流畅和高清。不设置时会显示全部清晰度。
        df: 3,  //视频播放默认采用的清晰度，取值：{0,1,2,3}，分别对应自动、流畅、高清、超清。
        // ban_seek:'off',//是否禁止拖拽进度条
        ban_seek_by_limit_time: v, //是否禁止拖拽进度至视频未播放到的位置，为on时只可在已播放过的进度范围内拖拽（向前拖拽）
      });
      this.loadPlayerInterval();
      this.end();
    },
    loadPlayerInterval() {
      this.player.on("s2j_onPlayerInitOver", (e) => {
        clearInterval(this.interval);
        this.interval = setInterval(() => {
          var bloon = this.player.j2s_realPlayStatus();
          if (bloon == false) {
            clearInterval(this.interval);
          }
          var currentTime = this.player.j2s_getCurrentTime();
          // console.log("播放进度");
          // console.log(currentTime);
          this.currentTime = currentTime;
          // if(currentTime!=this.currentTime){
          //     this.planReportedData(currentTime)
          // }
          this.planReportedData(currentTime);
        }, 10000);
      });
    },
    end() {
      this.player.on("s2j_onPlayOver", (e) => {
        // console.log("视频播放结束了.....");
        // console.log(e);
        //结束之后自动播放下一个
        //e 为videoId
        var arr = [];
        for (var i = 0; i < this.chapterList.length; i++) {
          for (var j = 0; j < this.chapterList[i].hours.length; j++) {
            arr.push(this.chapterList[i].hours[j]);
          }
        }
        var index = 0;
        for (var i = 0; i < arr.length; i++) {
          // console.log(arr[i]);
          if (arr[i].videoId == e) {
            if (i == arr.length - 1) {
              index = 0;
            } else {
              index = i + 1;
            }
          }
        }
        this.chooseVideo(arr[index]);

      })
    },
    planReportedData(currentTime) {
      var obj = {
        coursePackageId: this.coursePackageId,
        courseId: this.clickItem.courseId,
        classHourId: this.videoObj.classHourId,
        videoPlan: Number(Number(currentTime).toFixed(0)),
      };
      planReportedData(obj)
        .then((res) => {
          // console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });

      // console.log(obj);
      let arr = this.chapterList;
      // console.log(arr);
      for (const i in arr) {
        for (const j in arr[i].hours) {
          if (arr[i].hours[j].id == this.videoObj.classHourId) {
            arr[i].hours[j].studyTimeLength = Number(Number(currentTime).toFixed(0))
            console.log(Number(Number(currentTime).toFixed(0)));
            break;
          }
        }
      }

    },
  },
  destroyed() {
    if (this.player) {
      this.player.destroy();
    }
  },
};
</script>


<style lang="scss">
.muTitleBox{
  display: block;
  max-width: 240px;
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis;
}
.warn_messageBox{
  position: fixed;
  width:280px;
  height: 35px;
  line-height: 35px;
  background-color: #cccccc;
  color: #8b8787;
  top:50%;
  left: calc(50% - 140px);
  border-radius: 20px;
}
.account {
  color: #d3d3d3;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  margin-right: 20px;
  &:hover {
    .account-box {
      display: block;
    }
  }
  .account-box {
    z-index: 9999999;
    position: absolute;
    width: 100px;
    height: 100px;
    top: 38px;
    background: #fff;
    padding: 10px;
    display: none;
    img {
      width: 100px;
      height: 100px;
    }
  }
}
.dow-app {
  color: #d3d3d3;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  &:hover {
    .dow-box {
      display: block;
    }
  }
  .dow-box {
    z-index: 9999999;
    position: absolute;
    width: 100px;
    height: 100px;
    top: 38px;
    background: #fff;
    padding: 10px;
    display: none;
    img {
      width: 100px;
      height: 100px;
    }
  }
}

.el-drawer__header span {
  outline: none;
}
.el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
}
.upload-uploader {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}
.el-upload--picture-card {
  background-color: transparent;
  width: 80px !important;
  height: 80px !important;
  line-height: 80px;
  margin: 0 8px 8px 0;
}
.el-menu-item {
  padding-left: 20px !important;
  padding-right: 20px !important;
  &.on {
    background-color: #1d1d1d;
  }
}
.el-drawer__body {
  overflow: auto;
}
</style>